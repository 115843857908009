import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Penyewaan = () => (
    <Layout>
        <SEO title="Penyewaan" />
        <section id={"penyewaan"} className={"penyewaan"}>
            <div className={"section-title"}>
                <Container>
                    <h1>Jasa Penyewaan Layar LED (Videotron)</h1>
                    <p>
                        Nulla sit amet orci sed diam hendrerit rhoncus consectetur at nulla. Aliquam malesuada lectus nec ullamcorper dignissim. Suspendisse fermentum, est a
                        efficitur tristique, eros tortor molestie nulla, nec vulputate elit purus in nisi. Fusce maximus, dolor eget imperdiet ullamcorper, magna mauris
                        pellentesque ipsum, quis mollis velit orci non velit. Sed congue tortor mi, ac gravida turpis tempus sit amet. Praesent condimentum vel tellus at pretium.
                        In non mauris non nisl condimentum posuere non at dui. Sed ultricies sollicitudin feugiat. Ut gravida, massa eu euismod rhoncus, purus ipsum placerat mi,
                        sit amet egestas ligula eros nec enim. Vivamus tempor hendrerit porta. Nam viverra luctus lorem, id scelerisque leo dignissim et. Suspendisse at condimentum
                        tortor. In vel nibh sodales, dictum felis sed, tempor nisi. Aliquam erat volutpat.
                    </p>
                    <Row>
                        <Col>
                            <h2>Layar 3 m &times; 4 m </h2>
                            <p>
                                Proin efficitur, enim vel blandit facilisis, nisi ex pharetra nibh, sit amet tincidunt arcu erat ac est. Fusce imperdiet, odio vitae dictum
                                elementum, purus orci ullamcorper lectus, vitae faucibus ligula leo at tellus. Fusce dui dolor, condimentum vel molestie sit amet, hendrerit ut
                                elit. Integer convallis erat ex, ut elementum eros dapibus at. Proin sed maximus sem, eu placerat dolor. Nam tincidunt lorem ut augue venenatis
                                viverra. Nulla pellentesque, nunc sed rutrum dignissim, urna sem rhoncus lacus, suscipit fringilla lacus augue at risus. Aenean sed ante et dui
                                varius pretium eget eget tortor. In imperdiet velit urna, in euismod mauris malesuada sit amet. Ut auctor nunc ultricies justo rutrum, ut eleifend
                                lorem varius.
                            </p>
                            <ul>
                                <li>Nullam pretium dolor et purus eleifend, eget condimentum magna auctor.</li>
                                <li>Suspendisse eu mi et sapien malesuada finibus nec et libero.</li>
                                <li>Nulla nec mi hendrerit risus consequat dictum.</li>
                                <li>Quisque in dolor eu enim euismod tincidunt.</li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>Unit</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Unit</h3>
                        </Col>
                        <Col>
                            <h2>Layar 16 m &times; 9 m </h2>
                            <p>
                                Aliquam dui odio, maximus mollis est id, iaculis posuere arcu. Praesent sollicitudin est rutrum, ornare lectus ac, aliquam lorem. Morbi semper
                                dignissim dui. Nullam bibendum vel lectus ac consectetur. Pellentesque egestas, mauris at ullamcorper commodo, metus nibh euismod leo, ut fermentum
                                mauris erat at ligula. Nullam ut malesuada est, at tristique metus. Mauris ipsum ex, accumsan in est vel, blandit molestie sem. Etiam sed sem orci.
                            </p>
                            <ul>
                                <li>Nullam elementum nulla in orci ullamcorper, quis efficitur orci finibus.</li>
                                <li>Nam non arcu nec tortor suscipit rutrum.</li>
                                <li>Etiam non sapien in ipsum pretium porttitor eu accumsan libero.</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Layar 21 m &times; 9 m </h2>
                            <p>
                                Suspendisse quis accumsan risus. Proin at sapien auctor, sodales dui nec, ultricies metus. Sed risus urna, malesuada eget justo non, consequat
                                malesuada sem. Proin mattis lacinia lorem sed ullamcorper. Cras in nisl leo. Sed lacinia risus ac nibh iaculis ullamcorper. Integer pharetra dolor
                                eget elit gravida, et tempor lectus facilisis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed
                                elementum malesuada turpis. Fusce scelerisque, nibh non elementum laoreet, sapien tortor venenatis lorem, vulputate elementum est mi vel justo.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit metus sit amet leo rutrum convallis. Donec eleifend, purus tempor feugiat
                                dictum, libero nibh mattis velit, a rhoncus purus nisi at magna. Cras pharetra vel neque sed convallis.
                            </p>
                            <ul>
                                <li>Integer ut mi nec diam feugiat scelerisque.</li>
                                <li>Nunc in felis fringilla, lobortis enim et, tristique est.</li>
                                <li>Phasellus vel nibh non quam venenatis pulvinar.</li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>Unit</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    </Layout>
);

export default Penyewaan;
